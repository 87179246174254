@import "./variables";

.icon-states {
  cursor: pointer;

  path {
    fill: $interactionPrimary500;
  }

  circle {
    fill: transparent;
  }

  &:hover {
    circle {
      fill: $interactionPrimary100;
    }
  }

  &:active {
    circle {
      fill: $interactionPrimary200;
    }
  }
}

.manager {
  .drawer {
    font-size: 16px;
    background-color: #ffffff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .drawer-head {
    font-size: 16px;
    font-weight: 500;
    background-color: #f5f5f5;
    border: 1px solid #dee2e6;
    border-radius: 4px 4px 0 0;
  }

  .drawer-row {
    height: 48px;
    padding-left: 20px;
    line-height: 50px;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .drawer-row img {
    margin-right: 15px;
  }

  .drawer-row.selected {
    color: #000;
    background-color: #eceeef;
  }

  .drawer-row:last-child {
    border-radius: 0 0 4px 4px;
  }

  .section-title {
    display: flex;
    justify-content: space-between;
  }

  .download-version {
    font-size: 16px;
    font-weight: normal;
    color: #29302e;
  }

  .version-table-menu {
    display: flex;
    justify-content: flex-end;

    .dot-menu-icon {
      svg g path {
        fill: #bbbbbb;
      }

      &:hover {
        svg g path {
          fill: #0a3ab4;
        }
      }
    }
  }

  .version-action-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .version-show-more {
    display: flex;
    align-items: center;
    margin: 20px 0 64px;
    height: 32px;
    padding: 0 10px;
    cursor: pointer;

    &>span {
      font-size: 14px;
      font-weight: 600;
      color: #0a3ab4;
    }

    &>svg {
      transform: rotate(90deg);

      path {
        fill: #0a3ab4;
      }
    }
  }

  .env-version {
    color: #7a6769;
    font-size: 16px;
    font-weight: normal;
  }

  .env-version h4 {
    margin: 0;
  }

  .env-version span {
    margin: 1px 3px;
    height: 28px;
    border-radius: 4px;
    line-height: 22px;
    font-weight: bold;
    float: right;
  }

  .env-version .label {
    background-color: #0e0e0e;
  }

  .env-version .prod,
  .env-version .local {
    background-color: #6610f2;
  }

  .env-version .sandbox {
    background-color: #fd7e14;
  }

  .env-version .qa {
    background-color: #5cb85c;
  }

  .env-version .claybox,
  .env-version .ce {
    background-color: #337ab7;
  }

  .restore-box {
    display: block;
    height: 29px;
    border-radius: 4px;
    border: solid 1px #0275d8;

    font-size: 14px;
    line-height: 1.5;
    color: #007bff;

    font-weight: normal;
    cursor: pointer;
    transition: background-color 1s, color 1s, border 1s;
    float: right;
  }

  .restore-box-color {
    border: solid 1px #043ac4;
    color: #043ac4;
    font-weight: 500;
  }

  .copied {
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
    border: solid 1px #28a745;
    background-color: #28a745;
    transition: background-color 1s, color 1s, border 1s;
  }

  .info-icon {
    background-color: transparent;
    border-radius: 50%;
    padding: 0px 6px;
    color: #000;
    font-size: 10px;
    position: relative;
    bottom: 2px;
    border: 1px solid #000;
  }

  .info {
    background-color: #0067f3;
    border-radius: 50%;
    padding: 0 5px;
    color: white;
    font-size: 10px;
    position: relative;
    bottom: 2px;
  }

  .bot-upload-modal-line {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: bold;
    color: rgba(55, 58, 60, 0.4);
    margin: 7px 0;
  }

  .bot-upload-modal-line.active {
    color: #373a3c;
  }

  .bot-upload-modal-line .glyphicon {
    color: #28a745;
  }

  .label.draft {
    height: 28px;
    border-radius: 4px;
    background-color: #6c757d;
    line-height: 22px;
  }

  .toast {
    position: absolute;
    opacity: 0;
    width: calc(100% - 30px);
    height: fit-content;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #c3e6cb;
    transition: opacity 1s;
    line-height: 32px;
    z-index: 3;
  }

  .test-table {
    font-size: 16px;
    color: #373a3c;
    table-layout: fixed;
  }

  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    border: none;
    border-radius: 4px;
    font-weight: 600;
    color: #29302e;

    &:focus {
      outline: none;
    }
  }

  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th {
    &:hover {
      background-color: #f9f9f9;
      color: #0a3ab4;
    }
  }

  .table>thead>tr>th {
    font-size: 13px;
    font-weight: 600;
  }

  .table>thead>tr {
    font-size: 13px;
    font-weight: 600;
    border-bottom: 1px solid #dddddd;
  }

  .publish-line {
    font-size: 13px;
    font-style: italic;
    line-height: 1.48;
    color: #373a3c;
    margin-right: 10px;
  }

  .inner-addon {
    position: relative;
  }

  /* style glyph */
  .inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    pointer-events: none;
    font-size: 16px;
  }

  /* align glyph */
  .left-addon .glyphicon {
    left: 0px;
  }

  .right-addon .glyphicon {
    right: 0px;
  }

  /* add padding  */
  .left-addon input {
    padding-left: 30px;
  }

  .right-addon input {
    padding-right: 30px;
  }

  .download-arrow {
    width: 18px;
    margin: -1px 4px 0 0;
    text-align: end;
  }

  .delete-trashcan {
    cursor: pointer;
    margin-bottom: -7px;

    &:hover {
      g circle {
        fill: #ccdffa;
      }
    }

    &:active {
      g circle {
        fill: #98bdf7;
      }
    }
  }

  .not-uploaded {
    color: #cccccc;
    font-size: 18px;
  }

  .ready-to-upload {
    color: #ffbc00;
    font-size: 18px;
  }

  .uploaded {
    color: #1cab13;
    font-size: 9px;
    border: 2px solid #28a745;
    border-radius: 50%;
    padding: 3px 3px;
    font-weight: bold;
  }

  .file-state-column {
    display: flex;
    align-items: center;
    line-height: 31px;
  }

  .error-Sign {
    color: #d0021a;
    font-size: medium;
  }

  .error,
  .error-modal,
  .error-modal .bot-upload-modal-line,
  .error-modal .bot-upload-modal-line .glyphicon {
    color: #d0021b;
  }

  .error-modal .progress-bar,
  .error-modal .btn-primary {
    background-color: #d0021b;
    border-color: #d0021b;
  }

  .control-label {
    font-size: 16px;
    font-weight: bold;
    color: #373a3c;
    margin-bottom: 4px;
  }

  .conversation-button {
    width: 170px;
    height: 35px;
    font-size: 16px;
    background-color: #043ac4;
  }

  .text-anchor {
    cursor: pointer;
    color: #0a3ab4;
    font-size: 16px;
    font-weight: 400;
  }

  .Intent_content .description-line {
    width: 50%;
    float: left;
    margin-top: 0;
    padding-right: 24px;
  }

  .Intent_content {
    display: inline-block;
    width: 100%;
  }

  .Intent_content .fuzzy-matching-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #29302e;
  }

  .Intent_content .fuzzy-matching-description {
    font-size: 16px;
    line-height: 1.5;
    color: #29302e;
  }

  .Intent_content .fuzzy-matching-switch-container {
    display: flex;
    align-items: center;
    margin-top: 12px;
    color: #7a6769;

    .fuzzy-matching-switch {
      margin-left: 0px;
      margin-right: 7px;
    }

    .react-switch-handle {
      z-index: 9;
    }

    .react-switch-handle:hover {
      background: #0a3ab4 !important;
    }
  }

  .Intent_content .description-line+.description-line {
    padding: 24px;
    background-color: #eef2ff;
    line-height: 24px;
    position: relative;
    float: right;
    border-radius: 4px;
  }

  .Intent_table_data table tr td,
  .Intent_table_data table tr th {
    border-bottom: none;
    font-weight: normal;
  }

  .Intent_table_data table tr th+th {
    color: #7a6769;
    font-weight: normal;
  }

  .Intent_table_data table tr:hover td:nth-child(1) span:nth-child(1) {
    color: #0641cb;
  }

  .Intent_table_data table tr td:last-child {
    opacity: 0;
  }

  .Intent_table_data table tr:hover td:last-child {
    opacity: 1;
  }

  .Intent_table_data table tr td:first-child,
  .Intent_table_data table tr th:first-child {
    width: 200px !important;
  }

  .Intent_table_data table tr td:first-child {
    width: 200px;
  }

  .Intent_table_data table tr td:hover {
    cursor: pointer;
  }

  .Intent_table_data .s-alert-wrapper {
    display: none;
  }

  .table-subdata {
    color: #b52c1c;
  }

  .table-header-subdata {
    color: #716f6f;
  }

  .errorSection {
    position: relative;
    top: -25px;
    color: #b52c1c;

    span {
      &.warning {
        position: absolute;
        right: 0;
        top: -31px;
        border-bottom: #b52c1c solid 1px;
        width: 100%;
        text-align: right;
        pointer-events: none;
      }
    }
  }

  .back-div {
    display: flex;
  }

  .back-div a {
    margin-top: 5px;
  }

  .back-div .text_anchor:hover {
    text-decoration: none;
    cursor: pointer;
  }

  .Intent_table_data table tr td:nth-child(2),
  .Intent_table_data table tr th:nth-child(2) {
    width: 60%;
    overflow: visible;
    white-space: pre-wrap;
  }

  .Add_intent_button {
    line-height: 30px;
    margin-bottom: 10px;
    font-size: 16px;

    h4 {
      display: inline;
    }
  }

  .Add_intent_button strong {
    font-size: 18px;
  }

  .back-div .text_anchor>svg {
    margin-top: 5px;
  }

  .text_anchor {
    // width: 87px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #0641cb;
  }

  .Intent_button_with_table {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.5rem;
    align-items: center;
    float: right;
    margin-bottom: 15px;

    .dot-menu-icon {
      &>* {
        top: -8px;
      }
    }
  }

  .Intent_button_without_table {
    display: flex;
  }

  .Intent_table_data table tr td:first-child div {
    padding-left: 10px;
    line-height: 1.5;
  }

  .Intent_table_data table tr td:nth-child(2) div {
    line-height: 1.5;
  }

  .Intent_table_data table tr td:last-child {
    text-align: right !important;
    padding-right: 16px;
  }

  .Intent_table_data table tr td:last-child div {
    padding-top: 8px;
  }

  .Intent_table_data table tr:hover td:last-child {
    text-align: right !important;
    padding-right: 16px;
  }

  .Intent_table_data table tr:hover td:last-child div {
    padding-top: 8px;
  }

  .name_header {
    padding-left: 18px;
  }

  .intent_table_utterance_header {
    padding-left: 8px;
  }

  .save_open_icon {
    margin-right: 1px !important;
    color: blue !important;
    border: none !important;
    background: none !important;
    margin-top: 10px;
  }

  .save_open_icon:active {
    background: transparent !important;
    box-shadow: none;
  }

  .save_open_icon:focus {
    border: none !important;
    outline: none;
  }

  .uttrence_title {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
  }

  .uttrence_title h5 {
    font-size: 18px;
  }

  .intent_sub_name {
    width: 20%;
    margin-left: -313px;
  }

  .intent_title input {
    text-transform: capitalize;
    width: 115%;
  }

  .intent_sub_name input {
    text-align: end;
    color: #7a6769;
  }

  .Intent_subtitle {
    margin-top: -20px;
    margin-bottom: 30px;
    color: #7a6769;
    padding: 2px 16px;
  }

  .Utterence_table .react-bs-container-body,
  .Utterence_table .react-bs-container-body table td {
    overflow: visible;
  }

  .Utterence_table .react-bs-container-body table td div div input:focus,
  .Utterence_table .react-bs-container-body table td div div input:hover {
    background-color: #f9f9f9 !important;
  }

  .Utterence_table .react-bs-container-body table tr td:first-child,
  .Utterence_table .react-bs-container-header table tr th:first-child {
    width: 50%;
  }

  .Utterence_table .react-bs-container-body table tr td div div,
  .Utterence_table .react-bs-container-body table tr td div div input {
    background-color: transparent;
  }

  .Utterence_table .react-bs-container-body table tr td:nth-child(2),
  .Utterence_table .react-bs-container-header table tr th:nth-child(2) {
    width: 25%;
  }

  .Utterence_table .react-bs-container-body table tr td:nth-child(3),
  .Utterence_table .react-bs-container-header table tr th:nth-child(3) {
    width: 18%;
  }

  .Utterence_table .react-bs-container-body table tr td {
    border-bottom: none;
  }

  .Utterence_table table tr td:first-child div div div {
    left: 8px;
  }

  .Utterence_table .react-bs-container-header table tr th {
    font-size: 13px;
    line-height: 16px;
    color: #7a6769;
    border-bottom: none;
    letter-spacing: 0;

    &:hover {
      color: #000;
    }
  }

  .Utterence_table input {
    text-transform: capitalize;
  }

  .Utterence_title button svg path {
    fill: #083ab4;
  }

  .utterance_header {
    padding: 0 32px;
  }

  .Utterence_table table tr td:last-child {
    text-align: right !important;
    padding-right: 16px;
  }

  .Utterence_table table tr td:last-child div {
    padding-top: 8px;
  }

  .Utterence_table table tr:hover td:last-child {
    text-align: right !important;
    padding-right: 16px;
  }

  .Utterence_table table tr:hover td:last-child div {
    padding-top: 8px;
  }

  .utterance_data {
    height: 32px;

    input {
      text-transform: capitalize;
    }
  }

  .uttrence_title .text_anchor {
    display: flex;
    width: auto;
  }

  .uttrence_title .text_anchor:hover {
    text-decoration: none;
    cursor: pointer;
  }

  .uttrence_title .intent_title {
    position: relative;
    width: 50%;
  }

  .uttrence_title .intent_title label.MuiFormLabel-root {
    background: white;
    font-size: 16px !important;
  }

  .uttrence_title .intent_title>span {
    position: absolute;
    right: -200px;
    top: 6px;
    font-size: 16px;
    line-height: 24px;
    color: #7a6769;
    letter-spacing: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
  }

  .warning {
    font: "48px";
    color: "red";
  }

  #intent_modal .modal-header {
    padding: 15px 50px;
    border-bottom: 0;
    box-shadow: 1px 1px 9px -2px #c3c3c3;
    background-color: white;
  }

  #intent_modal .modal-header h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0;
  }

  #intent_modal .modal-footer {
    border-top: 0;
    padding: 15px 25px 40px;
  }

  #intent_modal .modal-footer a.text_anchor {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
  }

  #intent_modal .modal-body {
    height: 100px;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 50px;
  }

  #intent_modal .modal-dialog {
    width: 40%;
  }

  .stop_word_modal .modal-dialog {
    width: 100% !important;
    max-width: 908px;
  }

  .stop_word_modal .modal-body {
    height: auto !important;
    margin-left: 1px;
    font-size: 16px !important;
    font-family: "Open Sans";
  }

  .stopword_area {
    width: 100%;
    border-radius: 5px;
    height: 128px;
    position: relative;
    left: -1px;
    z-index: 0;
    top: -4px;
    resize: none;
    padding: 16px 17px 0px 16px;
  }

  .stopword_header {
    color: #8e8e8e;
    padding: 0 5px;
    background: #fff;
    position: relative;
    top: 10px;
    left: 12px;
    z-index: 9;
    font-size: 12px;
  }

  .stopword_modal_body {
    padding: 25px 40px 0 !important;
    background: none !important;
  }

  .stopword_modal .modal-content {
    background: white;
  }

  .stopword_modal_header {
    padding: 18px 40px !important;
  }

  .stopword_cancel_button {
    margin-right: 7px !important;
  }

  .stopword_modal_header {
    .modal-title {
      font-family: "Open Sans";
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #29302e;
    }
  }

  .stopword_text {
    margin-top: 10px;
    font-size: 20px;
  }

  .stopword_link {
    font-size: 16px;

    &.disabled {
      opacity: 0.65;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .stopword_link:hover {
    text-decoration: none;
    cursor: pointer;
  }

  .modal-content {
    border-radius: 0;
    background-color: #f9f9f9;

    .modal-footer {
      button+button {
        margin-left: 5px;
      }
    }
  }

  .table-header-data span:first-child {
    text-transform: capitalize;
    font-weight: 600;
    color: #29302e;
  }

  .uttranceRowData {
    max-height: 90px;
    text-transform: capitalize;
  }

  .entity_table_data {
    overflow: unset;
  }

  .publsh-tooltip img {
    height: 16px;
  }

  .publsh-tooltip {
    display: inline-block;
    margin-right: 5px;
  }

  .createNewInterface-container {
    display: block;
    margin: 2rem 8rem;
    background-color: white;

    @media (max-width: 1200px) {
      display: none;
    }

    &-content {
      display: flex;
      flex-direction: row;
      font-family: $mainFont;
      font-size: 16px;

      &--left {
        width: 45.5%;
        padding-right: 24px;
        font-size: 16px;

        .MuiTypography-body1 {
          font-family: $mainFont;
          font-size: 16px;
          line-height: 1.5;
        }

        .helperText {
          margin-left: 16px;
          font-family: "Open Sans";
          font-size: 12px;
          line-height: 1;
          color: red;
        }

        .altHelperText {
          margin-left: 16px;
          font-family: "Open Sans";
          font-size: 12px;
          line-height: 1;
          color: $backgroundGrey500;
        }

        .img-circle {
          background-color: #ddd;
          height: 4rem;
          width: 4rem;
          border-radius: 100px;
          margin-right: 24px;
          align-self: center;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            g g g circle {
              fill: transparent;
            }
          }
        }

        h3 {
          font-family: $mainFont;
          font-weight: 600;
          line-height: 1.5;
          font-size: 16px;
          margin-top: 40px;
        }

        p {
          font-family: $mainFont;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          color: #29302e;
          margin: 0;
          padding: 0;
        }

        .color-checkboxes {
          padding: 0;
          margin-right: 8px;
          margin-top: 6px;
          width: 18px;
          height: 18px;
          align-self: flex-end;
        }

        .lock-icon {
          cursor: pointer;
        }

        .input-style {
          font-size: 16px;
          line-height: 1.5;
          font-family: $mainFont;
        }

        .input-label-style {
          font-family: $mainFont;
        }
      }

      &--right {
        width: 55%;

        .widget-preview-container {
          width: 100%;
          height: 50.714285714285714rem;
          background-color: $backgroundGrey200;
          display: flex;
          align-items: center;
          flex-direction: column;

          .widget-preview-main {
            margin-top: 2.86rem;
            box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
            border-radius: 10px;

            &__header {
              display: flex;
              justify-content: space-between;
              height: 5rem;
              border-radius: 10px 10px 0 0;
              color: white;

              &--left {
                display: flex;
                align-items: center;
                vertical-align: middle;

                p {
                  font-family: $mainFont;
                  font-size: 16px;
                  font-weight: 600;
                  margin: 0;
                }

                .brand-icon {
                  height: 2.14rem;
                  width: 2.14rem;
                  background-color: $backgroundGrey300;
                  border-radius: 100px;
                  margin-right: 16px;
                }
              }

              &--right {
                display: flex;
                align-items: center;
                vertical-align: center;

                // svg {
                //   cursor: pointer;
                // }
              }
            }

            &__body {
              height: 27.3rem;
              background-color: white;
              padding-top: 20px;

              &-bubble {
                &--left {
                  margin-left: 28px;
                  align-items: center;
                  border-radius: 5px;
                  display: flex;
                  max-width: 290px;
                  padding: 15px 0 15px 20px;
                  font-family: $mainFont;
                  font-size: 16px;
                  position: relative;
                }

                &--right {
                  // background: #aaa5ff;
                  border-radius: 5px;
                  display: flex;
                  margin: 20px 24px 20px auto;
                  max-width: 223px;
                  padding: 15px 0 15px 15px;
                  font-family: $mainFont;
                  font-size: 16px;
                  color: white;
                }
              }

              &-button {
                // background-color: #ffa2a5;
                border-radius: 5px;
                padding: 15px 20px;
                display: inline-block;
                margin: 15px 0 0 5px;
                font-family: $mainFont;
                font-size: 14px;
                font-weight: 600;
                height: 50px;
              }

              small {
                color: #afbed3;
                font-size: 9px;
                font-family: $mainFont;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2px;
              }
            }

            &__footer {
              height: 4.9rem;
              background-color: #f8fbff;
              border-radius: 0 0 10px 10px;
              border-top: 1px solid #dee8f5;
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              padding: 26px 20px;
            }
          }

          .launcher-button {
            margin-top: 40px;
            width: 70px;
            height: 70px;
            border-radius: 100px;
            // background: #235B25;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 15px 10px 40px 0 rgba(0, 0, 0, 0.5);
            margin-right: 58px;
            align-self: flex-end;
          }
        }
      }

      &--rightside {
        position: fixed;
        right: 12%;
        display: flex;
        flex-direction: column;
        background-color: #f3f3f3;
      }
    }
  }

  .preview-button {
    margin-right: 8px;
  }

  .preview_button {
    margin-left: 16px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #0275d8;
    color: #007bff;
    font-weight: 400;
    margin-right: 0;
    padding: 10px 12px;
    background: none;
  }
}

.modal-content {
  background-clip: unset !important;
  border-radius: 0;
}

.intent_modal {
  .intent_modal_header {
    border: 0;
    box-shadow: 1px 1px 9px -2px #c3c3c3;
    background-color: white;
    padding-left: 24px;
  }

  .intent_modal_body {
    background: none;
    padding: 16px 24px;
  }

  .intent_modal_footer {
    border: 0;
    padding: 16px 24px;
  }

  .stopword_area {
    width: 100%;
    border-radius: 5px;
    height: 128px;
    position: relative;
    left: -1px;
    z-index: 0;
    top: -4px;
    resize: none;
    padding: 16px 17px 0px 16px;
  }

  .stopword_header {
    color: #8e8e8e;
    padding: 0 5px;
    background: #fff;
    position: relative;
    top: 10px;
    left: 12px;
    z-index: 9;
    font-size: 12px;
  }
}

.interfaces-tab {
  &-list {
    display: flex;
    margin-bottom: 40px;
    // justify-content: space-evenly;

    &-preview {
      margin-right: 16px;

      &--rectangle {
        width: 100px;
        height: 24px;
        // background-color: #6b6587;
        border-radius: 4px 4px 0 0;
      }

      &-bubbles {
        display: flex;
        flex-direction: column;
        width: 100px;
        height: 76px;
        // border: 1px solid #6b6587;
        border-radius: 0 0 4px 4px;
        padding: 6px 4px;

        &--user-bubble {
          align-self: flex-end;
        }
      }
    }

    &-info {
      display: inherit;
      width: 100%;

      div:first-of-type {
        margin-right: 22px;
        width: 182px;
      }

      div:nth-of-type(2) {
        margin-right: 95px;
        width: 250px;
      }

      div:nth-of-type(3) {
        width: 13rem;
      }

      small {
        font-size: 14px;
        font-style: italic;
        line-height: 1.43;
        color: #7a6769;
      }

      button {
        align-self: flex-end;
      }

      .trashcan-icon {
        cursor: pointer;

        &:hover {
          g g g circle {
            fill: #cbdffa !important;
          }
        }

        &:active {
          g g g circle {
            fill: #98bdf7 !important;
          }
        }
      }

      &--informational-text {
        color: #7a6769;
      }
    }
  }
}

.copy-icon {
  cursor: pointer;
  margin: 0 8px;
  align-self: flex-end;

  &:hover {
    g g g circle {
      fill: #cbdffa;
    }
  }

  &:active {
    g g g circle {
      fill: #98bdf7;
    }
  }
}

.newNavbarContainer {
  height: 5.714285714285714rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1), 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 12px 19vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;


  &-leftContent {
    display: inherit;
    align-items: center;

    h3 {
      margin: 0;
      padding-left: 16px;
      font-size: 16px;
      font-weight: 600;
      font-family: $mainFont;
      line-height: 1.5;
      color: #7a6769;
    }
  }
}

.current-version-list {
  table {
    width: calc(100% - 12px);

    thead {
      th {
        padding: 0.5rem !important;
      }
    }

    tbody {
      td {
        padding: 0.5rem !important;
      }
    }
  }
}

.training-utterance-table {
  display: unset;
}

.previewModal {
  overflow: hidden !important;
  display: flex !important;
  margin-left: auto !important;
  width: 35.7rem !important;

  .modal-header {
    border: 0;
  }

  .preview_Header {
    padding: 25px 23px !important;
    background-color: white;
    box-shadow: none;
    z-index: 99999999999999;

    span {
      float: right;

      &>img {
        &:first-child {
          padding-right: 7px;
        }
      }
    }
  }

  .preview_modal_body {
    background: white;
    border-top: #ccc solid 1px;
    padding: 0 !important;
  }
}

.solution-dialog {
  &-draft-cards {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: 24px;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .draft-card-actions {
    display: flex;
    align-items: center;
  }
}

.publish-button,
.train-solution-button {
  margin-right: 0;
}

.dialog-version-card {
  padding: 16px 16px 20px;
  border-radius: 4px;
  background-color: #eef2ff;
  position: relative;

  &.secondary {
    border: 1px solid #eef2ff;
    background-color: #fff;
  }

  &-header {
    display: flex;
    align-items: center;
  }

  &-title {
    font-size: 16px;
    font-weight: 600;
    color: #29302e;
    margin-right: 8px;
    text-transform: capitalize;
  }

  &-version {
    font-size: 16px;
    font-weight: normal;
    color: #716f6f;
  }

  &-desc {
    margin: 8px 0 16px;
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    color: #716f6f;
  }

  &-dotmenu {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.solution-header {
  padding: 12px 16px;
  background-color: #eef2ff;
  border-radius: 4px;

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 32px;

    &-left {
      display: flex;
      align-items: center;

      &:not(.error):not(.loading) {
        & g {
          fill: #249d6f;
        }
      }
    }
  }

  &-title {
    margin-left: 4px;
    font-size: 16px;
    font-weight: normal;
    color: #29302e;
  }

  &-buttons {
    display: flex;
    flex-wrap: nowrap;
  }
}

.solution-header-error-content {
  p {
    font-size: 1rem;
    font-weight: normal;
    color: #29302e;
    margin: 1rem 2rem;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
  }
}

.bot_layout {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  &__left {
    margin-right: 60px;
    margin-bottom: 40px;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  &__right {
    width: 100%;
    /* eslint-disable */
    width: -moz-available;
    width: -webkit-stretch;
    width: fill-stretch;
    /* eslint-enable */
  }
}

.menu__small_screen {
  display: none;
  margin: 1rem 0 2rem 0;

  @media (max-width: 1200px) {
    display: block;
  }

  &__notice {
    background-color: #f8f8f8;
    padding: 1rem;
    border-radius: 4px;

    &-text {
      color: #716f6f;
      margin-bottom: 0;
      font-size: 0.9rem;
    }
  }
}

.settings-page {
  &-delete {
    margin-top: 55px;
  }
}

.delete-solution-modal {
  &-container {
    &>p {
      font-size: 16px;
      line-height: 1.5;
      color: #29302e;
      margin-bottom: 1rem;
    }

    &>p:first-child {
      margin-bottom: 2rem;
    }
  }
}

.template-solution-modal {
  &-container {
    &>p {
      font-size: 16px;
      line-height: 1.5;
      color: #29302e;
      margin-bottom: 1rem;
    }

    &>p:first-child {
      margin-bottom: 2rem;
    }
  }
}

.pype-css-editor {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  &>* {
    flex: 1;
  }
}

.action-container {
  width: calc(100vw - 216px);

  &>section {
    overflow: hidden;
  }
}

.channels-tab {
  &-title {
    margin-bottom: 1rem;
  }

  &-description {
    display: grid;
    grid-template-columns: 1fr auto;

    &>p {
      font-size: 16px;
    }

    &>button {
      margin-left: 3rem;
      margin-right: 0;
    }
  }

  &-table {
    margin-top: 40px;
  }

  &-placeholders {
    display: grid;
    grid-template-rows: auto;
    row-gap: 28px;
    margin-top: 14px;
  }
}

.channel-list-item {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 14px 0;

  &-interface {
    .interface-name {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
      white-space: nowrap;
    }

    & span {
      color: $text-primary;
      font-size: 16px;
      margin-bottom: 0;
    }

    & p {
      width: auto;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.channel-list-environment {
  height: 100%;
  padding: 14px 0;

  &>p {
    color: $text-primary;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.5;
  }

  &>.pype {
    color: $color-placeholder;
  }
}

.channels-list:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.channel-list-lastedited {
  height: 100%;
  padding: 14px 0;

  &>p {
    color: $text-primary;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.5;
  }
}

.channel-list-menus {
  height: calc(100% - 14px);
  display: flex;
  justify-content: center;

  &>div>button {
    margin-top: 6px;
    margin-left: 8px;
  }
}

// placeholders
.channel-placeholder {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  row-gap: 28px;
  column-gap: 1.5rem;

  &-name {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    border-radius: 9px;
    background-color: $backgroundGrey300;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &-line {
    width: 100%;
    height: 1rem;
    min-height: 1rem;
    max-height: 1rem;
    border-radius: 2px;
    background-color: $backgroundGrey300;

    &.half {
      width: 50%;
    }
  }
}

.actions-placeholder {
  margin: 0;
  max-width: 800px;
  width: 100%;

  &-line {
    width: 100%;
    height: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    border-radius: 2px;
    background-color: $backgroundGrey300;
    margin: 2rem 0;

    &.even {
      width: 90%;
    }
  }
}

.skeleton-box {
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0));
    animation: shimmer 3s infinite;
    content: "";
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.customSpan {
  color: #0a3ab4;
  @apply text-base font-normal cursor-pointer ml-4;
}

.color-picker {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  overflow: hidden;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
  }
}

.hidden-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}