.previewPanel {
  #pypestream-wrapper {
    height: calc(100vh - 80px) !important;
  }
}

.preview-solution-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > button,
  & > svg {
    margin-left: 1rem;
  }
}
